import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const markdown = `

## 2023-01-01: Introducing Spectr Lab 

We are happy to introduce our new independent research lab!

Spectr Lab, is a spectral research and software development lab that is dedicated to furthering the availability of open source software software for spectral analysis. 

## What does that mean?

Spectral analysis is simply the analysis of data that is represented in the frequency domain. 

This is a very broad field that includes a wide variety of applications.

What we are most focused and passionate about is near infrared (NIR) spectroscopy. 

NIR spectroscopy is a non-invasive method of using light to determine the chemical composition of a substance. 

It is used in a wide variety of applications including medical diagnostics, food and beverage analysis, and environmental monitoring.

## Why are we doing this?

We believe that it is important to make the tools that are used in scientific research available to the public. 

We believe this is especially important when those tools are the only way truly understand what is inside of the things we consume.

## What are we working on?

We are focused on 2 major projects that we believe will help further open source spectrometry.

### 1. Spectral Fingerprinting

Spectral fingerprinting is a method of identifying a substance based on its spectral signature. 

Areas of exploration include:
1. Algorithmic reduction of signal to a fixed size fingerprint. This can be achieved using [discrete wavelet transforms](https://en.wikipedia.org/wiki/Discrete_wavelet_transform); a method to decompose a signal into a series of coefficients that can be used to reconstruct the original signal. In our case we can use the coefficients to represent a fixed sized spectral signature of a substance.

2. Fingerprint indexer using coefficient vector distance of fingerprints. By utilizing recent advancements like [Hierarchical Navigable Small Worlds](https://arxiv.org/abs/1603.09320) we can enable efficient and fast identification of similar signatures. Additionally, we can build on shoulders of the great work done by [Instant Domain Search](https://github.com/InstantDomain/instant-distance).

3. Fingerprint clustering and classification using supervised and unsupervised machine learning. This can be achieved using [K-Means](https://en.wikipedia.org/wiki/K-means_clustering) and [Hierarchical Clustering](https://en.wikipedia.org/wiki/Hierarchical_clustering) and more advanced methods like [Self-Organizing Maps](https://en.wikipedia.org/wiki/Self-organizing_map).

### 2. Desktop Application

In order for this technology to be useful to the public, it needs to be accessible. 

We are building a cross-platform desktop application that will allow users to easily collect NIR spectra and perform spectral analysis.

We are targeting a desktop application because of the required processing power and the ability to connect to a wide variety of hardware. 

Longer term we'll want to further develop the embedded capabilities of the application to enable it to run on a variety of devices, thereby making it possible to perform spectral analysis in the field.

### Why now?

We believe that the time is right for this technology to be made available to the public.


#### 1. The Cost

The cost of hardware has dropped significantly in the last few years.

Specifically, the cost of [NIR spectrometers](https://en.wikipedia.org/wiki/Near-infrared_spectroscopy) have dropped by an order of magnitude in the last 30 years.

More recently, there have been even [more developments](https://www.spiedigitallibrary.org/conference-proceedings-of-spie/8374/83740F/Spectral-imaging-device-based-on-a-tuneable-MEMS-Fabry-Perot/10.1117/12.919271.short) in MEMS spectrometers are pushing another order of magnitude in cost reduction. 

This cost reduction has been made possible specifically because of MEMS technology and it's ability to improve in cost along with Moore's Law. 

*Examples of MEMS impact on cost:*

> "Initial air bag technology used conventional mechanical ‘ball and tube’ type devices which were relatively complex, weighed several pounds and cost several hundred dollars... MEMS has enabled the same function ... into a single silicon chip, resulting in a tiny device that can be housed within the steering wheel column and costs only a few dollars" - [An Introduction to MEMS. Page 12](https://www.lboro.ac.uk/microsites/mechman/research/ipm-ktn/pdf/Technology_review/an-introduction-to-mems.pdf)

> "A typical optical switch can cost over $1000, but using MEMS, the same
level of functionality can be achieved for less than a dollar." - [An Introduction to MEMS. Page 18](https://www.lboro.ac.uk/microsites/mechman/research/ipm-ktn/pdf/Technology_review/an-introduction-to-mems.pdf)

> "Monolithic processing of this device as well as the reduced number of parts enable a very compact device with high reliability at a very low cost." - [An Introduction to MEMS. Page 31](https://www.lboro.ac.uk/microsites/mechman/research/ipm-ktn/pdf/Technology_review/an-introduction-to-mems.pdf)

> "Most of today’s magnetic sensors are silicon based, not only because of the ease of fabrication and their ability to be readily integrated with circuitry, but also because their high volume demand necessitates lower cost." - [An Introduction to MEMS. Page 41](https://www.lboro.ac.uk/microsites/mechman/research/ipm-ktn/pdf/Technology_review/an-introduction-to-mems.pdf)

Because of this cost reduction, we are excited to see the impact that this will have on the availability of open source spectrometry.

Additionally we think it's the perfect time to start building a community around this technology, starting with free and open source software.

#### 2. Machine Learning and Artificial Intelligence

The second reason we think this is the right time is because of the advancements in machine learning and artificial intelligence.

The hardware, software, and algorithms that are being developed in these fields are enabling us to do things that were previously impossible.

We believe that the combination of these technologies will enable us to build a tool that will be useful to the public.

*and so do others:*

> "MEMS have a long history and are continuously evolving. Exciting times lie ahead with acceleration of development, as sensors become more intelligent." - [Status of the MEMS Industry 2021 - Yole Développement](https://s3.i-micronews.com/uploads/2021/07/YINTR21180-Status-of-the-MEMS-Industry-2021_Sample.pdf)

> "The move toward more intelligence (software, processing, algorithms and the advent of AI) on the edge, proven by recent developments (new products, collaborations, funding, acquisitions)" - [Status of the MEMS Industry 2021 - Yole Développement](https://s3.i-micronews.com/uploads/2021/07/YINTR21180-Status-of-the-MEMS-Industry-2021_Sample.pdf)

Specifically, we are excited about the advancements in the ability to leverage image processing, vector space analysis, clustering, and classification with simple and inexpensive hardware.

### Whats next?

We are currently working on a proof of concept for spectral fingerprinting.

We are also working on a desktop application that will allow users to collect NIR spectra and perform spectral analysis.

Follow us on [GitHub](http://github.com/spectrlab) and [Twitter](http://twitter.com/spectrlab) for updates. 

### Updates

Next post: [Spectral Fingerprinting](/blog/spectral-fingerprinting)

`;

export const Post = () => {
  return <ReactMarkdown children={markdown} remarkPlugins={[remarkGfm]} />;
};
