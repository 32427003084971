import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const markdown = `

## 2023-01-05: Our Vision

We believe that the time is right for this technology to be made available to the public.

### Spectr App

We are building a cross-platform desktop application that will allow users to easily collect NIR spectra and perform spectral analysis.

### How the app works

**coming soon!**

Follow us on [GitHub](http://github.com/spectrlab) and [Twitter](http://twitter.com/spectrlab) for updates. 

`;

export const PostTwo = () => {
  return <ReactMarkdown children={markdown} remarkPlugins={[remarkGfm]} />;
};
