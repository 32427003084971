import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { Landing } from "./Landing";
import { Post } from "./Post";
import { PostTwo } from "./PostTwo";
// useNavigate
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  return (
    <div className="App-content">
      <div className="navbar">
        <h2
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/");
          }}
        >
          Spectr Lab
        </h2>
        <a
          style={{ cursor: "pointer", lineHeight: "3.75em" }}
          onClick={() => {
            navigate("/blog/introduction");
          }}
        >
          Blog
        </a>
      </div>
    </div>
  );
};

const App = () => {
  const [isLoaded, setIsLoaded] = React.useState(true);

  return (
    <Router>
      <div className="App">
        <Navbar />
        {isLoaded && (
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/blog/introduction" element={<Post />} />
            <Route path="/blog/spectral-fingerprinting" element={<PostTwo />} />
          </Routes>
        )}
      </div>
    </Router>
  );
};

export default App;
