import "./App.css";

export const Landing = () => {
  return (
    <div>
      <p>
        An independent research lab that is focused on furthering open source
        spectrometry.
      </p>

      <h3>Our Mission</h3>
      <p>
        We live in a world of uncertainty, where you never know what kind of
        contamination might be lurking in the food and water you're consuming.
      </p>
      <div style={{ border: "none", width: "100%" }}>
        <a
          style={{
            position: "absolute",
            top: "20px",
            right: "1rem",
          }}
          href="https://clipchamp.com/watch/VnGhHhCGt4g?utm_source=embed&utm_medium=embed&utm_campaign=watch"
        >
          <img
            style={{ height: "22px;" }}
            src="https://clipchamp.com/e.svg"
            alt="Made with Clipchamp"
          />
        </a>
        <iframe
          className="video"
          allow="autoplay;"
          allowFullScreen={true}
          src="https://clipchamp.com/watch/VnGhHhCGt4g/embed"
        ></iframe>
      </div>
      <p>
        Unfortunately, the spectrometry technology needed to detect these
        contaminants is often expensive and inaccessible to everyday people.
      </p>
      <p>
        That's why we are building technologies to further open-source
        spectrometry and make it easier for everyone to detect contamination in
        food, water and other consumables.
      </p>

      <h3>Projects (work in progress)</h3>

      <table>
        <thead>
          <tr>
            <th>Project</th>
            <th>Description</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Open Spectrometry</td>
            <td>
              A collection of open source spectrometry projects that are focused
              on making spectrometry more accessible to everyone.
            </td>
            <td>
              {" "}
              <a href="https://github.com/spectrlab">Link</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
